import styled, { css } from 'styled-components';

import { darken } from 'polished';

export const Container = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
`;

export const ErrorMensage = styled.p`
  font-size: 0.8rem;
  /* margin-top: 8px; */
  color: #c53030;
`;

export const Content = styled.div`
  background: ${props => (props.type_page === 'main' ? '#fff' : 'transparent')};
  border-radius: 10px;
  padding: 0 15px;
  width: 100%;

  border: 2px solid;
  /*   ${darken(0.3, '#e5e5e5')}; */

  display: flex;
  align-items: center;
  height: ${props => props.heigth};
  position: relative;

  margin-top: 0px;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #1d211c;
      border-color: #381c4f;
    `}


  ${props =>
    props.isFilled &&
    css`
      color: #653391;
    `}




  input {
    flex: 1;
    background: transparent;
    border: 0 !important;
    border-color: '#fff' !important;
    margin: 0;
    padding: 0 !important;
    margin-top: ${props => (props.heigth === '40px' ? 0 : '24px')};
    font-size: 1.2rem;
    line-height: 1.25rem;
    font-weight: 400;
    padding-left: 2px;
    border-color: transparent !important;
    height: 100%;

    &::placeholder {
      color: #747a85;
    }

    &:focus {
      border-color: transparent !important;
      border: 0 none;
      outline: 0;
    }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

&[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
&[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}
  }

  ${props =>
    props.description &&
    css`
      height: 115px;
      align-items: start;
      input {
        height: 50px;
        margin-bottom: 15px;
      }

      textarea {
        border: 1px solid #000000;
        background: #cccccc;
        width: 150px;
        height: 100px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        box-shadow: 1px 1px 2px #333333;
        -moz-box-shadow: 1px 1px 2px #333333;
        -webkit-box-shadow: 1px 1px 2px #333333;
        resize: none;
      }
    `}

  svg {
    /*  margin-left: 15px; */
  }
`;

export const LabelInput = styled.div`
  color: #5b636f;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.0015em;
  position: absolute;
  top: 8px;
  left: 15px;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 400px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }

 @media screen and (min-width: 401px) and (max-width: 619px) {
    text-overflow: inherit;
 }
  
  @media screen and (min-width: 620px) and (max-width: 680px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }
 
 
`

export const IconImg = styled.div`
  cursor: pointer;
  height: 20px;
`
