import React, { useState, useCallback, useEffect } from 'react';

import { Field } from 'formik';

import IconSearch from '../../../assets/icons/ic-search.svg';

import { Container, LabelInput, Content, ErrorMensage, IconImg } from './styles';

const Input = ({ name, icon: Icon, error, type = 'text', onBlur, value = '', ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);

  const [isFilled, setIsFilled] = useState(false);

  const [typeHooks, setTypeHooks] = useState('text');


  useEffect(() => {
    setTypeHooks(type);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container width={rest.width}>
      <Content
        isFilled={isFilled}
        isFocused={isFocused}
        isErrored={!!error}
        heigth={typeHooks === 'search' ? '40px' : rest.height}
        width={rest.width}
        type_page={rest.type_page}
        
      >
        {rest.label && <LabelInput>{rest.label}</LabelInput>}

        {typeHooks === 'search' && <img src={IconSearch} alt="Icone de pesquisa" width="20px" />}

        <Field
          autocomplete="off"
          name={name}
          type={typeHooks}
          value={value}
          onFocus={handleInputFocus}
          onBlur={() => {
            handleInputBlur();

            if (onBlur) {
              onBlur();
            }
          }}
          {...rest}
        />

        {Icon && (
            <IconImg
            
            
            onClick={e => {
              if (typeHooks && typeHooks === 'password') {
                setTypeHooks('text');
              }
  
              if (typeHooks && typeHooks === 'text') {
                setTypeHooks('password');
              }
            }}
          >
            <img src={typeHooks === 'password' ? Icon : rest.hiddenIcon} size={20}/> 
          </IconImg>
        )}
      </Content>

      {error ? <ErrorMensage>{error}</ErrorMensage> : <br />}
    </Container>
  );
};

export default Input;
