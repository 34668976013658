import React from 'react'

import {Container, LoadingContainer} from './styles'

export default function LoadingSpinner() {
    return (
        <Container>
            <LoadingContainer>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </LoadingContainer>
            
        </Container>
    )
}
